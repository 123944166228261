import axios from "axios";
export const executorService = {
  createExecutor,
  getExecutorListByOwner,
  executorVerification,
  sendExecutorInvitation,
  removeExecutor,
  signinExecutor,
  updateExecutor,
  updateExecuterVideoViewer,
  getReentExecutorAlbum,
  ownerPassingDate,
  getExecutorOwners
};
async function createExecutor(data, token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/executor-signup`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function signinExecutor(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/executor-signin`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function getExecutorListByOwner(ownerId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-executor-by-owner/${ownerId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function executorVerification(data, token) {

  console.log(token , 'token here ');

  return await axios.post(
    `${process.env.REACT_APP_API_URL}/executor-verification`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function sendExecutorInvitation(data, executorId, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/send-executor-invitation/${executorId}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function removeExecutor(executorId, token) {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/remove-executor/${executorId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function updateExecutor(data, executorId, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-executor/${executorId}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function updateExecuterVideoViewer(data, executorId) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/executor-isview/${executorId}`,
    data,
    { headers: { "Content-Type": "application/json" } }
  );
}

async function getReentExecutorAlbum(id, ownerId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-recent-executor-album?id=${id}&ownerId=${ownerId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function ownerPassingDate(id, data, token) {
  console.log(token, "token");
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/owner-passing-date/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function getExecutorOwners(email) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/find-owners-by-executorId/${email}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
