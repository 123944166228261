import axios from "axios";

export const folderService = {
  createFolders,
  getAlbumList,
  updateFolderThumbNailUrl,
  permanentlyDeleteAlbum,
  updateFileUrl,
  getMediasList,
  deleteAlbumFolder,
  updateAlbumFolder,
  getTrashedAlbums,
  restoreTrashedAlbums,
  uploadProfileViewer,
  updateName,
  signOutUserFromAllDevices,
  deleteCommonAccount,
  updateImageURL,
  getDailyRateData,
  updateDailyRate
};
async function createFolders(data, token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/create-folder`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function getAlbumList(ownerId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-owner-folder/${ownerId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

async function updateFolderThumbNailUrl(data) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-folder-thumbnails`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function updateAlbumFolder(data, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/rename-owner-folder`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function permanentlyDeleteAlbum(folderId, token) {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/delete-owner-folder/${folderId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function deleteAlbumFolder(albumId, token) {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/soft-delete-album/${albumId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function updateFileUrl(ownerFolderUrlData) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/update-files-url`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(ownerFolderUrlData),
      }
    );

    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    // throw error;
  }
}
async function getMediasList(ownerId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-all-files/${ownerId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

async function getTrashedAlbums(ownerId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-soft-delete-album/${ownerId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function restoreTrashedAlbums(folderId, token) {
  console.log(token, "token");
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/restore-owner-folders/${folderId}`,
    null,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function uploadProfileViewer(id, data) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/upload-profile-picture/${id}`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function updateName(id, data, role) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-${role}/${id}`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function signOutUserFromAllDevices(id) {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/sign-out-all/${id}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function deleteCommonAccount(id, token) {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/delete-common-account/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

async function updateImageURL(id, data) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-profile-url/${id}`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}


async function getDailyRateData(id , date) {
  try {
    return await axios.get(
      `${process.env.REACT_APP_API_URL}/getTodayPlan/${id}?date=${date}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  } catch (error) {
    console.log("Error in Daily Rate Data: ",error);
    
  }
 
}

async function updateDailyRate(data) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/post-today-data`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    // throw error;
  }
}