import axios from "axios";
export const planServices = {
  getPlans,
  checkOutSession,
  retriveCheckOutSession,
  getFreePlan,
  getSubscriptionHistory,
  getOwnerCurrentSubscription,
  storePayAsYouGoData,
  createPaymentIntent,
  paymentMethodOfStripe,
  stripeSubscription,
  customerStripe,
  getPendingPlanStatus,
  getUniqueVal
};

async function getPlans() {
  return await axios.get(`${process.env.REACT_APP_API_URL}/get-plans`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
async function getSubscriptionHistory(token) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-all-owner-plans`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function getOwnerCurrentSubscription(userId, token) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-owner-current-plan/${userId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function checkOutSession(data, token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/create-checkout-session`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function retriveCheckOutSession(data, token) {
  console.log(data, "retreive payload");
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/retrive-checkout-session`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function getFreePlan(data, token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/get-free-plan`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function storePayAsYouGoData(payAsYouGoData, token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/personal-plan-details`,
    payAsYouGoData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
// STRIPE
async function createPaymentIntent(checkOutplanData, customerId) {
  const amount = Math.round(
    (checkOutplanData?.discountPrice || checkOutplanData?.price) * 100
  );

  const data = new URLSearchParams({
    amount: amount.toString(),
    currency: "usd",
    customer: customerId,
  });

  try {
    const response = await axios.post(
      "https://api.stripe.com/v1/payment_intents",
      data.toString(),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    // throw error;
  }
}
async function paymentMethodOfStripe(paymentMethodId, customerId) {
  try {
    const response = await axios.post(
      `https://api.stripe.com/v1/payment_methods/${paymentMethodId}/attach`,
      { customer: customerId },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    // throw error;
  }
}

async function stripeSubscription(customerId, price) {
  try {
    const response = await axios.post(
      ` https://api.stripe.com/v1/subscriptions`,
      { customer: customerId, "items[0][price]": price },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    // throw error;
  }
}

async function customerStripe(customerId, price) {
  try {
    const response = await axios.post(
      ` https://api.stripe.com/v1/customers/${customerId}`,
      { "invoice_settings[default_payment_method]": price },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    // throw error;
  }
}
async function getPendingPlanStatus(ownerId, token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/get-owner-pending-plan/${ownerId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error, "Error getting pending plan");
  }
}


async function getUniqueVal(token) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-unique`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}