import axios from "axios";
export const mediaServices = {
  getOwnerFiles,
  updateFileUrl,
  uploadOwnerFiles,
  getTrashedFiles,
  softDeleteFiles,
  getRecentAlbums,
  updateTitleForMemories,
  permanentlyDeleteMemories,
  restoreTrashedFiles,
  getMediaDetails,
  updateMemory,
  getDailyUsage,
};

async function getOwnerFiles(data) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-owner-files/${data.folderId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function updateFileUrl(data) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-files-url`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function uploadOwnerFiles(ownerFolderData, token) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/upload-owner-files`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(ownerFolderData),
      }
    );

    return await response.json();
  } catch (error) {
    console.error("Error:", error);
  }
}

async function getTrashedFiles(ownerId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-soft-delete-files/${ownerId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function softDeleteFiles(fileId, token) {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/soft-delete-files/${fileId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function getRecentAlbums(ownerId) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-recent-album/${ownerId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}

async function updateTitleForMemories(ownerId, data, token) {
  console.log(token, "token");
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-media-title/${ownerId}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

async function permanentlyDeleteMemories(fileId, token) {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/delete-owner-files/${fileId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function restoreTrashedFiles(fileId, token) {
  console.log(token, "token");
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/restore-owner-files/${fileId}`,
    null,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function getMediaDetails(mediaID, token) {
  console.log(mediaID, "mediaID");
  console.log(token, "token");
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/get-file-details/${mediaID}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function updateMemory(updatedMediaDetails, token) {
  return await axios.put(
    `${process.env.REACT_APP_API_URL}/update-file-location`,
    updatedMediaDetails,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
async function getDailyUsage(ownerId, token) {
  try {
    return await axios.get(
      `${process.env.REACT_APP_API_URL}/getAllDailyRate/${ownerId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
  } catch (error) {
    console.log("Daily Data Insights API Error: " + error);
  }
}
