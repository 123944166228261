import { useEffect, useState } from "react";
import { Row, Col, Table, Card, Button, Modal } from "react-bootstrap";
import DashboardHeading from "../../../components/DashboardHeading";
import ChangeView from "../../../components/ChangeView";
import { folderService } from "../../../_services";
import SkeletonLoader from "../../../components/SkeletonLoader";
import DataNotFound from "../../../components/DataNotFound";
import { useAuth } from "../../../_hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import BillingPlans from "../billingPlans";
import CurrentPlanDetails from "../../../components/Modal/CurrentPlansDetails";
import { useDeviceScreen } from "../../../components/DeviceScreen";

const ExecutorOwnerAlbums = () => {
  const { isMobile } = useDeviceScreen();
  const navigate = useNavigate();
  const user = useAuth("user");
  const params = useParams();
  const ownerId = params.id;
  const ownerName = params.ownerName;
  const [isGridViewActive, setIsGridViewActive] = useState(true);
  const [isLoadingForList, setIsLoadingForList] = useState(false);
  const [albumListData, setAlbumListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nonImageAlbums, setNonImageAlbums] = useState([]);
  const [planDetailsPopUp, setPlanDetailsPopUp] = useState(false);

  // FETCH OWNER ALBUMS
  useEffect(() => {
    const invalidRoutes = [
      "dashboard",
      "memories",
      "albums",
      "loved-ones",
      "executers",
      "trash",
      "myaccount",
      "billingPlans",
      "serviceInstruction",
      "executors"
    ];
    if (ownerId && !invalidRoutes.includes(ownerId)) {
      fetchData(ownerId);
    }
  }, [ownerId]);

  const fetchData = async (ownerId) => {
    setIsLoadingForList(true);
    folderService.getAlbumList(ownerId).then((response) => {
      if (response?.data?.status === true) {
        setAlbumListData(response?.data?.data || []);
        setLoading(false);
        setIsLoadingForList(false);
      } else {
        console.log("Error");
        setIsLoadingForList(false);
      }
    });
  };

  // UPDATE THE EXPIRED IMG URL
  useEffect(() => {
    const checkThumbnailUrl = async (album) => {
      if (album?.thumbnailUrl) {
        try {
          await loadImage(album.thumbnailUrl);
          console.log("Thumbnail URL is an image:>", album.thumbnailUrl);
        } catch (error) {
          console.log("Thumbnail URL is not an image:>", album.thumbnailUrl);
          if (
            !nonImageAlbums.find(
              (nonImageAlbum) => nonImageAlbum._id === album._id
            )
          ) {
            setNonImageAlbums((prevAlbums) => [...prevAlbums, album]);
          }
        }
      }
    };

    albumListData.forEach((album) => checkThumbnailUrl(album));
  }, [albumListData]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = url;
    });
  };

  useEffect(() => {
    if (nonImageAlbums?.length > 0) {
      handleUpdateAlbumThumbnailUrls(nonImageAlbums);
    }
  }, [nonImageAlbums]);

  const handleUpdateAlbumThumbnailUrls = (fileDataArray) => {
    const updatePromises = fileDataArray?.map((album) => {
      const ownerAlbumUrlData = {
        fileName: album?.thumbnailFilename,
        _id: album?._id,
        path: album?.path,
      };
      return folderService.updateFolderThumbNailUrl(ownerAlbumUrlData);
    });

    Promise.all(updatePromises)
      .then((responses) => {
        responses.forEach((response) => {
          if (response?.data?.status === true) {
            console.log("File URL updated successfully");
            fetchData(ownerId);
          }
        });
      })
      .catch((error) => {
        console.log("Network ERROR", error);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-3 border-bottom mb-3">
        <DashboardHeading
          heading={
            ownerName ? `${ownerName}'s Albums` : `Executor's Owner Albums`
          }
        />
        <ChangeView
          setIsGridViewActive={setIsGridViewActive}
          isGridViewActive={isGridViewActive}
        />
      </div>
      <div>
        <Row></Row>
        {isLoadingForList ? (
          <>
            <SkeletonLoader />
          </>
        ) : albumListData.length === 0 ? (
          <DataNotFound isAlbum={true} />
        ) : (
          <>
            <div className="text-end">
              <Button onClick={() => setPlanDetailsPopUp(true)}>
                Current Plan Details
              </Button>
            </div>
            {!isGridViewActive || isMobile ? (
              <div className="grid-info-executor mt-3">
                <Row>
                  {albumListData.map((item, index) => (
                    <Col
                      lg={4}
                      xl={4}
                      md={6}
                      xs={12}
                      className="mb-3"
                      key={index}
                    >
                      <Card
                        onClick={() =>
                          navigate(
                            `/executor/${item?.folderName
                              ?.trim()
                              .replace(/\s+/g, "")}/${item._id}`
                          )
                        }
                        role="button"
                      >
                        <div className="edit-icon d-flex align-items-right justify-content-end px-3 "></div>
                        <Card.Body>
                          <div className="d-flex align-items-center gap-4 mb-3">
                            <div className="album_folder_section">
                              <img
                                src="/assets/folder.png"
                                className="album_folder_img"
                                alt=""
                              />
                              {item.thumbnailUrl && (
                                <img
                                  src={item.thumbnailUrl}
                                  className="album_img"
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="invite-user-info">
                              <div className="invite-user-detail">
                                <h5>
                                  <b className="text-capitalize">
                                    {item?.folderName}
                                  </b>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <div className="w-100">
                <div className="pb-3 mb-3">
                  <div className="table-container p-1">
                    <Table className="executer-table">
                      <thead>
                        <tr>
                          <th className="table-head-color"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {albumListData.map((item) => (
                          <tr
                            key={item._id}
                            onClick={() =>
                              navigate(
                                `/executor/${item?.folderName
                                  ?.trim()
                                  .replace(/\s+/g, "")}/${item._id}`
                              )
                            }
                            role="button"
                          >
                            <td>
                              <div className="album_folder_wrapper mb-0">
                                <div className="album_folder_section">
                                  <img
                                    src="/assets/folder.png"
                                    className="album_folder_img"
                                    alt=""
                                  />
                                  {item.thumbnailUrl && (
                                    <img
                                      src={item.thumbnailUrl}
                                      className="album_img"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="album_folder_detail">
                                  <div className="album_folder_detail text-capitalize">
                                    <h5>
                                      <b>{item?.folderName}</b>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="avatars"></div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {/* PLAN DETAILS POP UP */}
        <Modal
          show={planDetailsPopUp}
          onHide={() => setPlanDetailsPopUp(false)}
          size="lg"
          centered
          className="profile-modal-info"
        >
          <Modal.Body>
            <div className="owner-card">
              <CurrentPlanDetails ownerId={ownerId} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ExecutorOwnerAlbums;
